<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
      <span v-if="index === levelList.length - 1" class="no-redirect">{{item.meta.title}}</span>
      <a v-else @click.prevent="handleLink(item)">{{item.meta.title}}</a>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import * as pathToRegexp from "path-to-regexp"
import router from "../../router";

export default {
  name: "BreadcrumbIndex",
  data() {
    return {
      levelList: null
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
      // const first = matched[0]
      // if (!this.isIndex(first)) {
      //   matched = [{ path: "/index", meta: { title: "总览" }}].concat(matched)
      // }
      this.levelList = matched
      // this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    isIndex(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === "Index".toLocaleLowerCase()
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route
      const toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      console.log(redirect)
      console.log(path)
      if (redirect) {
        router.push(redirect)
        return
      }
      console.log(this.pathCompile(path))
      router.push(this.pathCompile(path))
    }
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  display: inline-block;
  font-size: 12px;
  line-height: 40px;
  margin-left: 8px;
}
</style>
