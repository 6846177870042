<template>
  <div/>
</template>

<script>
import {onMounted} from "vue"
import {useRoute} from "vue-router"
import router from "../../router"
import {loading, success} from "../../util/tipsUtils";
import {dingTalkLogin} from "../../api/login"
import {setToken} from "../../util/tokenUtils";
export default {
  name: "dingTalkLogin",
  setup() {
    const route = useRoute()
    onMounted(() => {
      let loadingObj = loading("正在登录...")
      const code = route.query.code
      const state = route.query.state
      console.log(code, state)
      dingTalkLogin({ code, state }, (res) => {
        success("登录成功");
        const accessToken = { expiresIn: res.expiresIn, value: res.value };
        const refreshToken = res.refreshToken;
        const data = { accessToken: accessToken, refreshToken: refreshToken };
        // 保存登录信息
        setToken(data)
        router.push("/index")
        loadingObj.close()
      }).catch((e) => {
        console.log(e)
        loadingObj.close()
        router.push("/login")
      })
    })
    return {}
  }
}
</script>
