const storage = {
  set(key, value) {
    localStorage.setItem(key, value);
  },
  get(key) {
    return localStorage.getItem(key);
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  setJson(key, value) {
    this.set(key, JSON.stringify(value));
  },
  getJson(key) {
    const value = this.get(key);
    if (value) {
      return JSON.parse(value);
    }
    return undefined;
  }
}
export default storage;
