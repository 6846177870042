import strongUtils from "../util/storageUtils";
const currentUserKey = "cloud-learning-user";

export function setUser(data) {
  return strongUtils.setJson(currentUserKey, data)
}

export function getUser() {
  const user = strongUtils.getJson(currentUserKey)
  if (user) {
    return user
  }
  return undefined
}

