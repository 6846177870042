<template>
  <div v-if="routeObj">
    <div class="aside-title" v-if="!opened && routeObj.meta">
      <h2 class="el-menu-item menu-title" style="height: 40px; line-height: 46px">{{routeObj.meta.title}}</h2>
    </div>
    <el-menu
      :default-active="activeMenu"
      class="el-menu-vertical"
      :class="{'padding-zero': opened}"
      :router="true"
      :collapse="opened"
      background-color="#f0f0f0"
      text-color="#000000"
      active-text-color="#07c160">
      <template v-for="(item, index) in routes" :key="index">
        <el-sub-menu :index="item.path" class="sub-menu-item" v-if="!item.hidden && item.children && item.children.length">
<!--          <li class="el-menu-item el-menu-item-title" v-if="!opened && !item.hidden && item.children && item.children.length">-->
<!--            {{item.meta.title}}-->
<!--          </li>-->
          <template #title>
            <el-icon>
              <component :is="item.meta.icon"/>
            </el-icon>
            <span>{{item.meta.title}}</span>
          </template>
          <template v-for="(subItem, idx) in item.children" :key="index + '-' + idx">
            <el-sub-menu :index="subItem.path" v-if="!subItem.hidden && subItem.children && subItem.children.length" :key="index + '-' + idx">
              <template #title>
                <el-icon>
                  <component :is="subItem.meta.icon"/>
                </el-icon>
                <span>{{subItem.meta.title}}</span>
              </template>
              <el-menu-item v-for="(c, y) in subItem.children" :index="routeObj.path + (item.path ? '/' : '') + item.path + (subItem.path ? '/' : '') + subItem.path + (c.path ? '/' : '') + c.path" :key="index + '-' + idx + '-' + y" v-show="!c.hidden">
                <i :class="c.meta.icon"/>
                <template #title>{{c.meta.title}}</template>
              </el-menu-item>
            </el-sub-menu>
            <el-menu-item :index="routeObj.path + (item.path ? '/' : '') + item.path + (subItem.path ? '/' : '') + subItem.path" v-else-if="!subItem.hidden">
              <el-icon>
                <component :is="subItem.meta.icon"/>
              </el-icon>
              <template #title>{{subItem.meta.title}}</template>
            </el-menu-item>
          </template>
        </el-sub-menu>
        <el-menu-item :index="routeObj.path + (item.path ? '/' : '') + item.path" v-else-if="!item.hidden">
          <el-icon v-if="item.meta.icon">
            <component :is="item.meta.icon"/>
          </el-icon>
          <template #title>{{item.meta.title}}</template>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
import {getRoutes} from "@/util/authorityUtils";
import store from "@/store";
import {ref, watch} from "vue";
import {useRoute} from "vue-router";
import {Search} from "@element-plus/icons-vue";
import router from "@/router";

export default {
  name: "AsideIndex",
  components: {Search},
  computed: {
    opened() {
      return !store.getters.getAsideStatus
    }
  },
  setup() {
    const route = useRoute();
    const routeMap = getRoutes();
    const routes = ref([]);
    const routeItemList = route.path.split("/");
    let key = route.path;
    if (routeItemList.length > 1) {
      key = "/" + routeItemList[1]
    }
    const routeObj = ref(routeMap[key])
    if (routeObj.value) {
      routes.value = routeObj.value.children || []
    }
    // 当前显示菜单
    const activeMenu = ref("")
    const changeActiveMenu = function() {
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        activeMenu.value = meta.activeMenu;
        return
      }
      if (path === "/" || path === "") {
        activeMenu.value = "";
        return
      }
      activeMenu.value = path
    }
    changeActiveMenu()
    // 监控路由变化
    watch(() => router.currentRoute.value.path, (toPath) => {
      console.log("toPath:", toPath)
      changeActiveMenu()
    },{ immediate: true, deep: true })

    return {
      routeObj,
      routes,
      activeMenu
    }
  }
}
</script>
<style scoped lang="scss">
.aside-title {
  position: fixed;
  z-index: 99;
  background: #f1f1f1;
  width: 210px;
}
.el-menu-vertical {
  border: 0;
  padding: 40px 0;
  ::v-deep .el-menu-item {
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    .el-icon-menu {
      margin: 0 5px 0 0;
      width: 24px;
      font-size: 18px;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  ::v-deep .el-submenu__title {
    font-size: 12px;
    height: 40px;
    line-height: 40px;
  }
  &:not(.el-menu--collapse) {
    width: 210px;
    min-height: 400px;
  }
  .sub-menu-item {
    &:first-child {
      padding-top: 0;
    }
    .el-menu-item-title {
      display: flex;
      align-items: flex-start;
      line-height: 24px;
      height: 34px;
      top: 10px;
      &:hover, &:focus {
        background: none;
        cursor: text;
      }
    }
  }
}
.padding-zero {
  padding: 0;
}
.menu-title {
  font-size: 16px;
  font-weight: 600;
  cursor: text;
  &:hover, &:focus {
    background: none;
  }
}
</style>
