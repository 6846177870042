import {getCurrentUser} from "@/api/login/index";
import {getUserInfo} from "@/api/organizational/user";
import {setUser} from "@/util/userUtils";
import storage from "@/util/storageUtils";

function hasAuthority(authorityObjs, route) {
  if (route.meta && route.meta.authorities) {
    return authorityObjs.some(authorityObj => route.meta.authorities.includes(authorityObj))
  } else {
    return true
  }
}

export function filterAsyncRoutes(routes, authorities) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasAuthority(authorities, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, authorities)
      }
      res.push(tmp)
    }
  })
  return res
}

export function deleteAuthorities() {
  storage.remove("authorityList");
}

export function setAuthorities(authorities) {
  deleteAuthorities();
  storage.setJson("authorityList", authorities);
}

export function getAuthorities() {
  const authorities = storage.getJson("authorityList");
  if (authorities) {
    return authorities.authorities
  }
  return null;
}

export function getUserAuthorities() {
  return new Promise((resolve, reject) => {
    getCurrentUser(res => {
      let authorities = res.authorities;
      // 保存权限信息
      setAuthorities(res);
      // 获取用户信息
      getUserInfo(res.username, user => {
        setUser(user);
        return resolve(authorities);
      }).catch(e => {
        reject(e)
      });
    }).catch(e => {
      reject(e)
    })
  });
}

export function checkAuthorities(value) {
  if (value && value instanceof Array && value.length > 0) {
    const authorities = getAuthorities();
    const authoritiesAttrs = value
    return authorities.some(authorityObj => {
      return authoritiesAttrs.includes(authorityObj)
    })
  } else {
    console.error("need authorities! Like ['admin']")
    return false
  }
}

export function getRouteList() {
  return storage.getJson("routeList");
}

export function getRoutes() {
  return storage.getJson("routeMap")
}

export function setRoutes(routes) {
  storage.remove("routes");
  storage.remove("routeMap");
  storage.remove("routeList");
  storage.setJson("routes", routes);
  const routeMap = {}
  const routeList = []
  if (routes && routes.length) {
    for (const route of routes) {
      routeMap[route.path] = route
      if (route.hidden) {
        continue
      }
      const routeChildrenList = []
      if (route.children && route.children.length) {
        for (const child of route.children) {
          if (child.hidden) {
            continue
          }
          const menuList = []
          if (child.children && child.children.length) {
            for (const subChild of child.children) {
              if (subChild.hidden) {
                continue
              }
              menuList.push({
                title: subChild.meta.title,
                path: route.path + (child.path ? "/" : "") + child.path + (subChild.path ? "/" : "") + subChild.path
              })
            }
          }
          if (child.path) {
            routeChildrenList.push({title: child.meta.title, path: route.path + (child.path ? "/" : "") + child.path, menuList: menuList})
          }
        }
      }
      if (route.meta && route.path && !route.hidden) {
        routeList.push({title: route.meta.title, path: route.path, menuList: routeChildrenList})
      }
    }
  }
  storage.setJson("routeList", routeList);
  storage.setJson("routeMap", routeMap);
}


