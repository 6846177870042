<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition>
        <keep-alive>
          <component :is="Component"/>
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script>
import {refreshToken} from "./util/tokenUtils";
import {onMounted} from "vue";

export default {
  name: "App",
  setup() {
    onMounted(() => {
      document.body.style.setProperty('--el-color-primary', '#07c160');
    })
    // 每次进页面都要是否刷新token
    refreshToken()
  }
};
</script>

<style>
ul li{
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
}
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  border: 0;
  min-height: 100%;
  background-color: #f7f7f7;
}
</style>
