<template>
  <div class="dashboard">
    <!--    <div class="feature-box feature-left-box feature-menu-box">-->
    <!--      <div class="feature-box-warp">-->
    <!--        <div class="feature-menu-title">我的导航</div>-->
    <!--        <div class="feature-menu-main"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="feature-box feature-right-box">-->
    <!--      <div class="feature-box-warp">-->
    <!--        <div class="feature-menu-title">账号信息</div>-->
    <!--        <div class="feature-menu-main"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="feature-box feature-left-box">-->
    <!--      <div class="feature-box-warp">-->
    <!--        <div class="feature-menu-title">预警事项</div>-->
    <!--        <div class="feature-menu-main"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="feature-box feature-right-box">-->
    <!--      <div class="feature-box-warp">-->
    <!--        <div class="feature-menu-title">公告</div>-->
    <!--        <div class="feature-menu-main"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="feature-box feature-left-box">-->
    <!--      <div class="feature-box-warp">-->
    <!--        <div class="feature-menu-title">待办事项</div>-->
    <!--        <div class="feature-menu-main"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="feature-box feature-right-box">-->
    <!--      <div class="feature-box-warp">-->
    <!--        <div class="feature-menu-title">帮助文档</div>-->
    <!--        <div class="feature-menu-main"></div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="feature-box operation-box">
      <div class="operation">
        <div class="title">
          总浏览量/PV
          <el-tooltip class="item" effect="dark" content="页面被浏览的次数。每打开一个页面或每刷新一次均记录1次" placement="top">
            <i class="el-icon-warning-outline"/>
          </el-tooltip>
        </div>
        <div class="value">{{totalVisitMap.pv || 0}}</div>
        <div class="bottom">
          <span class="text" v-if="!yesterdayVisitMap.pv">昨日无变化</span>
          <span class="text" v-else>昨日 <span style="color: red;">+{{yesterdayVisitMap.pv}}</span></span>
        </div>
      </div>
      <div class="operation">
        <div class="title">
          总访客数/UV
          <el-tooltip class="item" effect="dark" content="1天之内，访问网站的用户数，一天内同一访客多次访问网站计算1次" placement="top">
            <i class="el-icon-warning-outline"/>
          </el-tooltip>
        </div>
        <div class="value">{{totalVisitMap.uv || 0}}</div>
        <div class="bottom">
          <span class="text" v-if="!yesterdayVisitMap.uv">昨日无变化</span>
          <span class="text" v-else>昨日 <span style="color: red;">+{{yesterdayVisitMap.uv}}</span></span>
        </div>
      </div>
      <div class="operation">
        <div class="title">
          总访问次数/VV
          <el-tooltip class="item" effect="dark" content="visit view，一个session算一次，页面停留超过10分钟重新计算" placement="top">
            <i class="el-icon-warning-outline"/>
          </el-tooltip>
        </div>
        <div class="value">{{totalVisitMap.vv || 0}}</div>
        <div class="bottom">
          <span class="text" v-if="!yesterdayVisitMap.vv">昨日无变化</span>
          <span class="text" v-else>昨日 <span style="color: red;">+{{yesterdayVisitMap.vv}}</span></span>
        </div>
      </div>
    </div>
    <div class="feature-box operation-chart-box">
      <div class="operation-chart full-width">
        <div class="operation-chart-header">
          <span>浏览趋势</span>
          <span style="float: right;color: #999;font-size: 12px;">查看记录</span>
        </div>
        <div class="operation-chart-canvas" id="pv-chart"></div>
      </div>
    </div>
    <div class="feature-box operation-chart-box">
      <div class="operation-chart">
        <div class="operation-chart-header">
          <span>访客趋势</span>
        </div>
        <div class="operation-chart-canvas" id="uv-chart"></div>
      </div>
      <div class="operation-chart">
        <div class="operation-chart-header">
          <span>访客分布</span>
        </div>
        <div class="operation-chart-canvas" id="uv-distribution-chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import {ref, onMounted} from "vue";
import {getDayPvList, getDayUvList, getIpCitySummaryList, getVisitSummary} from "@/api/index";

export default {
  name: "HomeIndex",
  components: {
  },
  setup() {
    const totalVisitMap = ref({
      pv: 0,
      uv: 0,
      vv: 0
    })
    const yesterdayVisitMap = ref({
      pv: 0,
      uv: 0,
      vv: 0
    })
    const getDaysAgo = (day) => {
      let yesterday = new Date();
      yesterday.setTime(yesterday.getTime() - day * 24*60*60*1000);
      let month = yesterday.getMonth()+1
      if (month < 10) {
        month = "0" + month
      }
      return yesterday.getFullYear()+"-" + month + "-" + yesterday.getDate();
    }
    // 汇总
    getVisitSummary({}, res => {
      totalVisitMap.value = res
    })
    const yesterdayDate = getDaysAgo(1)
    // 昨天汇总
    getVisitSummary({startDate: yesterdayDate, endDate: yesterdayDate}, res => {
      yesterdayVisitMap.value = res
    })
    onMounted(() => {
      // 浏览趋势
      const pvxAxisData = []
      const pvSeriesData = []
      getDayPvList({startDate: getDaysAgo(7), endDate: getDaysAgo(0)}, res => {
        if (res && res.length) {
          for (const re of res) {
            pvxAxisData.push(re.visitDate);
            pvSeriesData.push(re.pv || 0)
          }
          // 浏览量
          let pvChart = echarts.init(document.getElementById("pv-chart"));
          const pvChartOption = {
            tooltip: {},
            xAxis: {
              data: pvxAxisData,
            },
            yAxis: {},
            series: [
              {
                name: "浏览量",
                type: "line",
                data: pvSeriesData,
              },
            ],
          }
          // 绘制图表
          pvChart.setOption(pvChartOption)
          //自适应大小
          window.onresize = function () {
            pvChart.resize();
          }
        }
      })
      // 访客趋势
      const uvxAxisData = []
      const uvSeriesData = []
      getDayUvList({startDate: getDaysAgo(7), endDate: getDaysAgo(0)}, res => {
        if (res && res.length) {
          for (const re of res) {
            uvxAxisData.push(re.visitDate);
            uvSeriesData.push(re.uv || 0)
          }
          // 访客趋势
          let uvChart = echarts.init(document.getElementById("uv-chart"));
          const uvOption = {
            tooltip: {},
            xAxis: {
              data: uvxAxisData,
            },
            yAxis: {},
            series: [
              {
                name: "访客量",
                type: "line",
                data: uvSeriesData,
              },
            ],
          }
          // 绘制图表
          uvChart.setOption(uvOption)
          //自适应大小
          window.onresize = function () {
            uvChart.resize();
          }
        }
      })
      // ip分布
      const ipCityXAxisData = []
      const ipCitySeriesData = []
      getIpCitySummaryList({}, res => {
        if (res && res.length) {
          for (const re of res) {
            ipCityXAxisData.push(re.ipCityName);
            ipCitySeriesData.push(re.uv || 0)
          }
          // 访客分布
          const uvDistributionChart = echarts.init(document.getElementById("uv-distribution-chart"));
          // 指定图表的配置项和数据
          const uvDistributionOption = {
            tooltip: {},
            xAxis: {
              data: ipCityXAxisData
            },
            yAxis: {},
            series: [{
              name: "访客",
              type: "bar",
              data: ipCitySeriesData
            }]
          }
          // 使用刚指定的配置项和数据显示图表。
          uvDistributionChart.setOption(uvDistributionOption);
          window.onresize = function () {
            uvDistributionChart.resize();
          }
        }
      })
    })
    return {
      totalVisitMap,
      yesterdayVisitMap
    }
  }
}
</script>

<style lang="scss" scoped>
  .dashboard {
    margin: 10px;
    .feature-box {
      width: calc(100% - 20px);
      margin: 10px;
      min-height: 100px;
      background-color: #FFFFFF;
      display: inline-block;
      .feature-box-warp {
        padding: 16px;
      }
    }
    .feature-left-box {
      width: calc(66.6666% - 20px);
    }
    .feature-right-box {
      width: calc(33.3333% - 20px);
    }
    .feature-menu-box {

    }
    .operation-box {
      .operation {
        background: #ffffff;
        width: calc(33.3333% - 52px);
        text-align: center;
        padding: 20px;
        display: inline-block;
        border-radius: 4px;
        &:nth-child(2) {
          margin: 0 18px;
        }
        .title {
          color: #666666;
        }
        .value {
          font-size: 36px;
          margin: 20px 0;
          font-weight: 500;
        }
        .bottom {
          font-size: 12px;
          color: #666666;
          display: inline-grid;
          .text {
            background: #f2f2f2;
            padding: 5px 16px;
            border-radius: 16px;
          }
        }
      }
    }
    .operation-chart-box {
      // margin: 18px 0;
      background: #f2f2f2;
      .operation-chart {
        background: #ffffff;
        border-radius: 4px;
        width: calc(50% - 9px);
        display: inline-block;
        &:nth-child(2) {
          margin-left: 18px;
        }
        .operation-chart-header {
          padding: 18px 18px 0 18px;
        }
        .operation-chart-canvas {
          width: 100%;
          height:300px;
        }
      }
      .full-width {
        width: 100%;
      }
    }
  }
</style>
