<template>
  <div class="footer"/>
</template>
<script>
export default {
  name: "FooterIndex"
}
</script>
<style scoped lang="scss">
.footer {
}
</style>
