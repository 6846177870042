<template>
  <div>
    <div class="layout-header-nav" :class="{'absolute': isShowFunctionMenu}">
      <div class="layout-header-nav-start">
        <div class="logo">
          <a href="/" title="知新学堂">
            <span style="font-size: 20px;font-weight: 700;color: #ffffff;">知新学堂</span>
<!--            <img src="../assets/logo.svg" alt="知新学堂">-->
          </a>
        </div>
        <!--        <a class="btn dropdown-item" :class="{'is-active' : isShowFunctionMenu}" @click="isShowFunctionMenu = !isShowFunctionMenu">-->
        <!--          所有功能<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!--        </a>-->
        <router-link class="btn" :to="{path: '/'}">总览</router-link>
      </div>
      <div class="layout-header-nav-center">
        <div>
          <a class="btn" @click="goto(item.path)" v-for="(item, index) in functionList" :key="item.path">
            <span v-if="index !== functionList.length - 1">{{item.title}}</span>
            <el-dropdown v-else-if="moreMenu">
              <span class="el-dropdown-link">
                {{item.title}}
                <el-icon><ArrowDown /></el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu class="more-function-dropdown">
                  <el-dropdown-item @click="goto(r.path)" v-for="r in item.children" :key="r.path">{{r.title}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </a>
        </div>
      </div>
      <div class="layout-header-nav-end">
        <div class="right-menu">
          <div class="right-menu-item">
            <i class="icon el-icon-message"></i>
          </div>
          <el-dropdown class="avatar-container" trigger="click">
            <div class="avatar-wrapper">
              <span style="margin-right: 5px;">{{user.name}}</span>
              <el-icon style="vertical-align: middle">
                <More />
              </el-icon>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="user-dropdown">
                <router-link to="/account">
                  <el-dropdown-item>
                    账号信息
                  </el-dropdown-item>
                </router-link>
                <el-dropdown-item divided>
                  <span style="display:block;" @click="logout">退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <el-drawer
      :before-close="functionMenuDrawerBeforeClose"
      v-model="isShowFunctionMenu"
      :withHeader="false"
      direction="ttb"
      class="function-menu"
      modal-class="function-menu-modal"
      destroy-on-close>
      <el-row>
        <el-col :span="4" class="left-box">
          <div class="function-menu-nav">
            <div class="function-menu-nav-label">
              <span @mouseover="changeNav('')" class="title" :class="{'is-active': navActive === ''}">所有功能</span>
            </div>
            <div class="function-menu-nav-list">
              <ul class="function-menu-nav-list-ul">
                <li @mouseover="changeNav(item.path)" @click="goto(item.path)" class="function-menu-nav-item" :class="{'is-active': navActive === item.path}" v-for="item in routeList" :key="item.path">{{item.title}}</li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :span="20" class="right-box">
          <el-row class="right-box-row" :gutter="20">
            <el-col :span="16">
              <div class="function-menu-content">
                <div class="function-menu-header">
                  <div class="function-menu-search">
                    <el-input v-model="keyword" @keydown.enter="searchFunction" placeholder="搜索功能名称" class="function-menu-search-input">
                      <template #suffix>
                        <i @click="searchFunction" class="el-input__icon el-icon-search"></i>
                      </template>
                    </el-input>
                  </div>
                  <div class="function-menu-tags">
                    <div class="function-menu-tag" @click="goto(item.path)" v-for="item in randomRouteList" :key="item.path">{{item.title}}</div>
                  </div>
                </div>
                <div class="function-menu-content-main">
                  <ul class="function-menu-content-main-ul" v-for="(itemList, index) in functionItemList" :key="index">
                    <li class="menu-box" v-for="(item, i) in itemList" :key="i">
                      <div class="menu-box-title" @click="goto(item.path)">{{item.title}}</div>
                      <div class="menu-box-content">
                        <ul>
                          <li v-for="(m, idx) in item.menuList" :key="idx">
                            <div class="menu-title" @click="goto(m.path)">{{m.title}}</div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="right-row">
                <div class="block">
                  <div class="title">一站式解决方案</div>
                  <div class="desc">方便易搭建，功能丰富易用</div>
                </div>
                <div class="block">
                  <div class="title">多终端支持</div>
                  <div class="desc">支持电脑、手机、APP、微信、H5、小程序观看</div>
                </div>
                <div class="block">
                  <div class="title">定制化服务</div>
                  <div class="desc">多功能自由组合，量身定制专属企业培训系统</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import store from "../store";
import {loginOut} from "@/api/login";
import {success} from "@/util/tipsUtils";
import router from "@/router";
import {getUser} from "@/util/userUtils";
import {ref} from "vue"
import {getRouteList} from "@/util/authorityUtils";

export default {
  name: "LayoutHeader",
  components: {
  },
  computed: {
    opened() {
      return !store.getters.getAsideStatus
    }
  },
  setup() {
    const logout = () => {
      loginOut()
      success("登出成功")
      router.push("/login")
    }
    const user = getUser()
    const isShowFunctionMenu = ref(false)
    const functionMenuDrawerBeforeClose = (done) => {
      done()
    }
    const routeList = getRouteList()
    // 头部菜单栏
    const functionList = ref([])
    const moreMenu = ref(false)
    if (routeList && routeList.length > 13) {
      for (let i = 0; i < routeList.length; i++) {
        if (functionList.value.length === 12) {
          moreMenu.value = true
          functionList.value.push({title: "更多", path: "", children: []});
        }
        if (i < 12) {
         functionList.value.push(routeList[i]);
        } else {
          functionList.value[functionList.value.length - 1].children.push(routeList[i])
        }
      }
    } else {
      functionList.value = routeList
      functionList.value.push({title: "更多", path: "", children: []});
    }
    function getRandomArrayItem(arr, count) {
      let shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    }
    const randomRouteList = getRandomArrayItem(routeList, 3);
    const allFunctionList = []
    const itemMap = {"": allFunctionList}
    if (routeList && routeList.length) {
      for (const r of routeList) {
        if (r.menuList && r.menuList.length) {
          const itemMapList = [];
          const rc = [];
          for (const child of r.menuList) {
            if (child.menuList && child.menuList.length) {
              allFunctionList.push(child)
              itemMapList.push(child)
            } else {
              rc.push(child)
            }
          }
          if (rc.length) {
            const c = {title: r.title, path: r.path, menuList: rc}
            allFunctionList.push(c)
            itemMapList.push(c)
          }
          itemMap[r.path] = itemMapList
        }
      }
    }
    const functionItemList = ref([]);
    const keyword = ref("");
    const formatItemList = (itemList) => {
      // 过滤
      if (keyword.value) {
        const temps = []
        for (const item of itemList) {
          if (item.title.indexOf(keyword.value) > -1) {
            temps.push(item)
            continue;
          }
          const subItemTemps = []
          for (const subItem of item.menuList) {
            if (subItem.title.indexOf(keyword.value) > -1) {
              subItemTemps.push(subItem);
            }
          }
          if (subItemTemps.length) {
            item.menuList = subItemTemps
            temps.push(item)
          }
        }
        itemList = temps
      }
      functionItemList.value[0] = [];
      functionItemList.value[1] = [];
      functionItemList.value[2] = [];
      functionItemList.value[3] = [];
      if (itemList && itemList.length) {
        const num = functionItemList.value.length;
        let i = 0
        for (const r of itemList) {
          if (i % num === 0) {
            functionItemList.value[0].push(r)
          } else if (i % num === 1) {
            functionItemList.value[1].push(r)
          } else if (i % num === 2) {
            functionItemList.value[2].push(r)
          } else {
            functionItemList.value[3].push(r)
          }
          i++;
        }
      }
    }
    formatItemList(allFunctionList);
    const navActive = ref("")
    const changeNav = (path) => {
      navActive.value = path
      formatItemList(itemMap[path])
    }
    const searchFunction = () => {
      formatItemList(itemMap[navActive.value])
    }
    const goto = (path) => {
      if (!path) {
        return
      }
      isShowFunctionMenu.value = false
      router.push({path: path})
    }
    return {
      moreMenu,
      user,
      logout,
      routeList,
      functionList,
      isShowFunctionMenu,
      functionMenuDrawerBeforeClose,
      functionItemList,
      navActive,
      changeNav,
      goto,
      keyword,
      searchFunction,
      randomRouteList
    }
  }
};
</script>

<style scoped lang="scss">
.layout-header-nav {
  background: #000000;
  color: #FFFFFF;
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 1px 0 #f5f5f5;
  box-shadow: none;
  z-index: 99999;
  .layout-header-nav-start {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    .logo {
      display: flex;
      font-size: 0;
      margin-right: 20px;
      padding-left: 20px;
      align-items: center;
      img {
        width: 108px;
        height: 30px;
      }
    }
    .btn {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      margin: 0 5px;
      white-space: nowrap;
      height: 100%;
      color: #ffffff;
      &:hover {
        color: $--color-primary;
      }
      ::v-deep .el-dropdown {
        font-size: 12px;
        color: #ffffff;
      }
    }
    .dropdown-item {
      display: inline-flex;
      align-items: center;
    }
    .is-active {
      color: $--color-primary;
    }
  }
  .layout-header-nav-center {
    flex: 1 1 600px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 100%;
    .btn {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      margin: 0 10px;
      white-space: nowrap;
      height: 100%;
      color: #ffffff;
      &:hover {
        color: $--color-primary;
      }
      ::v-deep .el-dropdown {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
  .layout-header-nav-end {
    justify-content: flex-end;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    .right-menu {
      float: right;
      height: 100%;
      &:focus {
        outline: none;
      }
      .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: #ffffff;
        vertical-align: middle;
        .icon {
          padding: 15px 0;
        }
        &.hover-effect {
          cursor: pointer;
          transition: background .3s;
          &:hover {
            background: rgba(0, 0, 0, .025)
          }
        }
      }
      .avatar-container {
        margin-right: 20px;
        .avatar-wrapper {
          position: relative;
          cursor: pointer;
          margin: 0;
          padding: 0 10px;
          font-size: 12px;
          color: #ffffff;
          span {
            line-height: 50px;
          }
          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
::v-deep .function-menu {
  margin: 0;
  padding: 0;
  left: 0;
  top: 50px!important;
  background: #FFFFFF;
  box-shadow: 0 8px 20px 0 rgb(55 99 170 / 10%);
  min-height: 600px;
  .el-row {
    min-height: 100%;
    .left-box {
      .function-menu-nav {
        padding-top: 20px;
        .function-menu-nav-label {
          padding: 10px 0;
          .title {
            font-size: 12px;
            cursor: default;
            padding-left: 30%;
            &:hover, &.is-active {
              color: $--color-primary;
            }
          }
        }
        .function-menu-nav-list {
          .function-menu-nav-list-ul {
            .function-menu-nav-item {
              font-size: 12px;
              cursor: pointer;
              padding: 8px 0 6px 30%;
              &:hover, &.is-active {
                color: $--color-primary;
              }
            }
          }
        }
      }
    }
    .right-box {
      background: #f3f5f8;
      .right-box-row{
        height: 600px;
        overflow-y: auto;
      }
      .function-menu-content {
        padding-top: 20px;
        padding-left: 20px;
        .function-menu-header {
          margin-bottom: 20px;
          .function-menu-search {
            display: inline-block;
            .function-menu-search-input {
              width: 300px;
              height: 34px;
              line-height: 34px;
            }
          }
          .function-menu-tags {
            margin-left: 20px;
            display: inline-block;
            line-height: 34px;
            vertical-align: bottom;
            .function-menu-tag {
              display: inline-block;
              background: rgba(205, 216, 229, .5);
              margin: 0 10px;
              padding: 0 20px;
              border-radius: 2px;
              cursor: pointer;
              &:hover {
                color: #FFFFFF;
                background: $--color-primary;
              }
            }
          }
        }
        .function-menu-content-main {
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
          .function-menu-content-main-ul {
            flex: 0.22;
          }
          .menu-box {
            margin-bottom: 20px;
            .menu-box-title {
              font-size: 14px;
              color: #0052d9;
              font-weight: 500;
              border-bottom: 1px solid #e6e6e6;
              padding: 10px 0;
              cursor: pointer;
              &:hover {
                color: $--color-primary;
              }
            }
            .menu-box-content {
              .menu-title {
                padding: 6px 0;
                cursor: pointer;
                &:hover {
                  color: $--color-primary;
                }
                &:first-child {
                  padding-top: 12px;
                }
              }
            }
          }
        }
      }
      .right-row {
        padding: 20px 20px 0 0;
        .block {
          display: block;
          background-image: linear-gradient(0deg,#fff,#f3f5f8);
          border: 2px solid #fff;
          box-shadow: 8px 8px 20px 0 rgb(55 99 170 / 10%), -8px -8px 20px 0 #fff;
          border-radius: 4px;
          box-sizing: border-box;
          flex: 1;
          height: 92px;
          padding: 18px;
          transition: all .3s linear;
          position: relative;
          pointer-events: auto;
          margin-bottom: 20px;
          .title {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 500;
            color: #000;
            line-height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .desc {
            font-size: 12px;
            color: #98a3b7;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.absolute {
  position: absolute;
  box-shadow: 0 8px 20px 0 rgb(55 99 170 / 10%);
}
::v-deep .function-menu-modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.user-dropdown {
  ::v-deep .el-dropdown-menu__item {
    font-size: 12px;
    line-height: 24px;
    &:hover {
      background-color: #FFFFFF;
      color: $--color-primary;
    }
  }
}
.more-function-dropdown {
  ::v-deep .el-dropdown-menu__item {
    font-size: 12px;
    line-height: 34px;
    color: #000000;
    &:hover {
      background-color: #FFFFFF;
      color: $--color-primary;
    }
  }
}
::v-deep .el-input__inner {
  height: 34px;
  line-height: 34px;
  border-color: #f3f5f8;
  &:focus, &:hover {
    border-color: #f3f5f8;
  }
}
::v-deep .el-input__icon {
  line-height: 34px;
  cursor: pointer;
  &:hover {
    color: $--color-primary;
  }
}
.layout-header-nav .layout-header-nav-center .btn[data-v-bafa59f2] .el-dropdown {
  color: #ffffff;
}
:focus-visible {
  outline: none;
}
</style>
