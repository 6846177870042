import {createRouter, createWebHistory} from "vue-router";
import Layout from "../components/Layout";
import LayoutEmpty from "../components/LayoutEmpty";
import LayoutNotAside from "../components/LayoutNotAside";
import Login from "@/views/login/Index"
import workWeChatLogin from "@/views/login/workWeChat"
import dingTalkLogin from "@/views/login/dingTalk"
import Index from "@/views/home/Index"

export const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    hidden: true
  },
  {
    path: "/work-we-chat",
    name: "workWeChatLogin",
    component: workWeChatLogin,
    hidden: true
  },
  {
    path: "/ding-talk",
    name: "dingTalk",
    component: dingTalkLogin,
    hidden: true
  },
  {
    path: "",
    name: "Index",
    component: LayoutNotAside,
    meta: { title: "仪表盘", icon: "el-icon-odometer" },
    hidden: true,
    children: [
      {
        path: "index",
        meta: { title: "总览", icon: "el-icon-odometer" },
        component: Index,
      }
    ]
  }
];

export const asyncRoutes = [
  {
    path: "/learn",
    name: "learn",
    component: Layout,
    meta: { title: "课程", icon: "Notebook", authorities: ["learning"], breadcrumb: false },
    children: [
      {
        path: "",
        name: "learnIndex",
        component: () => import("@/views/learn/index"),
        meta: { title: "概览", name: "learning", icon: "PieChart", authorities: ["learning"] },
        children: []
      },
      {
        path: "lesson",
        name: "lesson",
        component: LayoutEmpty,
        meta: { title: "课程", icon: "Document", authorities: ["map"] },
        children: [
          {
            path: "",
            name: "learnLesson",
            component: () => import("@/views/learn/lesson/index"),
            meta: { title: "在线课程", icon: "Tickets", authorities: ["learning_list"] },
            children: []
          },
          {
            path: "category",
            name: "learnCategory",
            component: () => import("@/views/learn/category/index"),
            meta: { title: "课程分类", icon: "Collection", authorities: ["learning_category"] },
          },
          {
            path: "trash",
            name: "learnLessonTrash",
            component: () => import("@/views/learn/lesson/trash/index"),
            meta: { title: "回收站", icon: "Delete", authorities: ["learning_list"] },
            children: []
          },
          {
            path: "edit",
            name: "learnLessonEdit",
            component: () => import("@/views/learn/lesson/edit/index"),
            meta: { title: "课程编辑", icon: "Edit", activeMenu: "/learn/lesson" },
            hidden: true
          },
        ]
      },
      {
        path: "topic",
        name: "topic",
        component: LayoutEmpty,
        meta: { title: "专题", icon: "Operation", authorities: ["map"] },
        children: [
          {
            path: "",
            name: "learnTopic",
            component: () => import("@/views/learn/topic/index"),
            meta: { title: "专题管理", icon: "DocumentCopy", authorities: ["learn_map_topic"] },
          },
          {
            path: "edit",
            name: "learnTopicEdit",
            component: () => import("@/views/learn/topic/edit/index"),
            meta: { title: "专题编辑", icon: "Edit", activeMenu: "/learn/topic" },
            hidden: true
          },
          {
            path: "category",
            name: "learnTopicCategory",
            component: () => import("@/views/learn/topic/category/index"),
            meta: { title: "专题分类", icon: "Collection", authorities: ["learn_map_topic_category"] },
          },
        ]
      },
      {
        path: "certificate",
        name: "certificate",
        component: LayoutEmpty,
        meta: { title: "证书", icon: "Collection", authorities: ["map"] },
        children: [
          {
            path: "template",
            name: "certificateTemplateList",
            component: () => import("@/views/certificate/template/index"),
            meta: { title: "证书模版", icon: "Tickets", authorities: ["learn_map"] },
          },
          {
            path: "template/edit",
            name: "certificateTemplateEdit",
            component: () => import("@/views/certificate/template/edit/index"),
            meta: { title: "证书模版编辑", icon: "Edit", activeMenu: "/learn/certificate/template" },
            hidden: true
          },
          {
            path: "",
            name: "certificateList",
            component: () => import("@/views/certificate/index"),
            meta: { title: "证书列表", icon: "Postcard", authorities: ["learn_map"] },
          }
        ]
      },
      // {
      //   path: "map",
      //   name: "map",
      //   component: LayoutEmpty,
      //   meta: { title: "学习地图", icon: "MapLocation", authorities: ["map"] },
      //   children: [
      //     {
      //       path: "",
      //       name: "learnMap",
      //       component: () => import("@/views/learn/map/index"),
      //       meta: { title: "地图管理", icon: "Guide", authorities: ["learn_map"] },
      //     },
      //     {
      //       path: "edit",
      //       name: "learnMapEdit",
      //       component: () => import("@/views/learn/map/edit/index"),
      //       meta: { title: "地图编辑", icon: "Edit", activeMenu: "/learn/map" },
      //       hidden: true
      //     },
      //   ]
      // },
      // {
      //   path: "order",
      //   name: "order",
      //   component: LayoutEmpty,
      //   meta: { title: "订单", icon: "Notification", authorities: ["map"] },
      //   children: [
      //     {
      //       path: "",
      //       name: "orderList",
      //       component: () => import("@/views/learn/order/index"),
      //       meta: { title: "订单列表", icon: "CopyDocument", authorities: ["learn_map"] },
      //     }
      //   ]
      // },
      {
        path: "data",
        name: "data",
        redirect: "sign",
        component: LayoutEmpty,
        meta: { title: "数据中心", icon: "ScaleToOriginal", authorities: ["map"] },
        children: [
          {
            path: "sign",
            name: "learnData",
            component: () => import("@/views/learn/report/signup"),
            meta: { title: "课程报名统计", icon: "PieChart", authorities: ["learn_map"] },
          },
          {
            path: "lessonstudy",
            name: "lessonStudyReport",
            component: () => import("../views/learn/report/lessonstudy"),
            meta: { title: "课程学习统计", icon: "Odometer", authorities: ["learn_map"] }
          },
          {
            path: "memberstudy",
            name: "memberStudyReport",
            component: () => import("../views/learn/report/memberstudy"),
            meta: { title: "用户学习统计", icon: "Finished", authorities: ["learn_map"] }
          },
        ]
      },
    ]
  },
  {
    path: "/exam",
    name: "exam",
    component: Layout,
    meta: { title: "考试", icon: "el-icon-video-camera", authorities: ["exam"], breadcrumb: false },
    children: [
      {
        path: "",
        name: "examIndex",
        component: () => import("@/views/exam/index"),
        meta: { title: "概览", name: "exam", icon: "PieChart", authorities: ["exam"] },
        children: []
      },
      {
        path: "question-lib",
        component: LayoutEmpty,
        meta: { title: "题库", icon: "Tickets", authorities: ["exam"] },
        children: [
          {
            path: "category",
            name: "examQuestionLibCategory",
            component: () => import("@/views/exam/question-lib/category"),
            meta: { title: "题目分类", icon: "Collection", authorities: ["exam_question_lib"] },
          },
          {
            path: "",
            name: "examQuestionLibList",
            component: () => import("@/views/exam/question-lib"),
            meta: { title: "题目管理", icon: "DocumentCopy", authorities: ["exam_question_lib"] },
          },
          {
            path: "single-choice",
            name: "singleChoiceEdit",
            component: () => import("@/views/exam/question-lib/single-choice"),
            meta: { title: "单选题编辑", icon: "CircleCheck", authorities: ["exam_question_lib"] },
          },
          {
            path: "multi-choice",
            name: "multiChoiceEdit",
            component: () => import("@/views/exam/question-lib/multi-choice"),
            meta: { title: "多选题编辑", icon: "DocumentChecked", authorities: ["exam_question_lib"] },
          },
          {
            path: "judgment",
            name: "judgmentEdit",
            component: () => import("@/views/exam/question-lib/judgment"),
            meta: { title: "判断题编辑", icon: "CircleClose", authorities: ["exam_question_lib"] },
          },
          {
            path: "fill-blank",
            name: "fillBlankEdit",
            component: () => import("@/views/exam/question-lib/fill-blank"),
            meta: { title: "填空题编辑", icon: "MoreFilled", authorities: ["exam_question_lib"] },
          },
          {
            path: "subjective",
            name: "subjectiveEdit",
            component: () => import("@/views/exam/question-lib/subjective"),
            meta: { title: "简答题编辑", icon: "Edit", authorities: ["exam_question_lib"] },
          }
        ]
      },
      {
        path: "paper",
        component: LayoutEmpty,
        meta: { title: "试卷", icon: "Memo", authorities: ["learning"] },
        children: [
          {
            path: "category",
            name: "examPaperCategory",
            component: () => import("@/views/exam/paper/category"),
            meta: { title: "试卷分类", icon: "Collection", authorities: ["learning_category"] },
          },
          {
            path: "",
            name: "examPaperList",
            component: () => import("@/views/exam/paper"),
            meta: { title: "试卷管理", icon: "Memo", authorities: ["learning_list"] },
          },
          {
            path: "normal",
            name: "examPaperNormal",
            component: () => import("@/views/exam/paper/normal"),
            meta: { title: "静态试卷编辑", icon: "Edit", authorities: ["learning_list"] },
          },
          {
            path: "random",
            name: "examPaperRandom",
            component: () => import("@/views/exam/paper/random"),
            meta: { title: "随机试卷编辑", icon: "EditPen", authorities: ["learning_list"] },
          },
          // {
          //   path: "mock",
          //   name: "examPaperMock",
          //   component: () => import("@/views/exam/paper/mock"),
          //   meta: { title: "模拟试卷编辑", icon: "el-icon-bank-card", authorities: ["learning_list"] },
          // },
        ]
      },
      {
        path: "exam",
        component: LayoutEmpty,
        meta: { title: "考试", icon: "Files", authorities: ["comment"] },
        children: [
          {
            path: "category",
            name: "examCategory",
            component: () => import("@/views/exam/category"),
            meta: { title: "考试分类", icon: "Collection", authorities: ["comment_sensitive_setting"] }
          },
          {
            path: "",
            name: "examList",
            component: () => import("@/views/exam/list"),
            meta: { title: "考试管理", icon: "Tickets", authorities: ["comment_list"] },
          },
          {
            path: "edit",
            name: "examEdit",
            component: () => import("@/views/exam/list/edit"),
            meta: { title: "考试编辑", icon: "Edit", authorities: ["comment_sensitive_setting"] },
          }
        ]
      },
      {
        path: "answer",
        component: LayoutEmpty,
        meta: { title: "答卷", icon: "DocumentCopy", authorities: ["comment"] },
        children: [
          {
            path: "",
            name: "examAnswerList",
            component: () => import("@/views/exam/answer/list"),
            meta: { title: "答卷管理", icon: "DocumentCopy", authorities: ["comment_list"] }
          },
          {
            path: "mark",
            name: "examAnswerMark",
            component: () => import("@/views/exam/answer/mark"),
            meta: { title: "答卷批改", icon: "Check", authorities: ["comment_sensitive_setting"] }
          }
        ]
      }
    ]
  },
  {
    path: "/live",
    name: "live",
    component: Layout,
    meta: { title: "直播", icon: "VideoCamera", authorities: ["live"], breadcrumb: false },
    children: [
      {
        path: "",
        name: "liveIndex",
        component: () => import("@/views/live/index"),
        meta: { title: "概览", name: "live", icon: "PieChart", authorities: ["live"] },
        children: []
      },
      {
        path: "channel",
        name: "channelList",
        component: LayoutEmpty,
        meta: { title: "频道", icon: "VideoCamera", authorities: ["live_channel"] },
        children: [
          {
            path: "",
            name: "channelList",
            component: () => import("@/views/live/channel/index"),
            meta: {title: "频道管理", icon: "Tickets", authorities: ["live_channel"]},
          },
          {
            path: "edit",
            name: "channelEdit",
            component: () => import("@/views/live/channel/edit"),
            meta: { title: "频道编辑", icon: "Edit", activeMenu: "/live/channel" },
            hidden: true
          },
          {
            path: "category",
            name: "channelCategory",
            component: () => import("@/views/live/category/index"),
            meta: { title: "频道分类", icon: "Collection", authorities: ["live_category"] }
          },
        ]
      },
      {
        path: "lecturer",
        name: "lecturer",
        component: LayoutEmpty,
        meta: { title: "讲师", icon: "Avatar", authorities: ["lecturer"] },
        children: [
          {
            path: "list",
            name: "lecturerList",
            component: () => import("@/views/live/lecturer/index"),
            meta: { title: "讲师管理", icon: "Avatar", authorities: ["lecturer_list"] },
          },
          {
            path: "edit",
            name: "lecturerEdit",
            component: () => import("@/views/live/lecturer/edit"),
            meta: { title: "讲师编辑", icon: "Edit", activeMenu: "/lecturer/list" },
            hidden: true
          }
        ]
      },
    ]
  },
  {
    path: "/news",
    component: Layout,
    meta: { title: "新闻", icon: "el-icon-news", authorities: ["news"], breadcrumb: false },
    children: [
      {
        path: "",
        name:"news",
        component: () => import("@/views/news/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["news"] },
        children: []
      },
      {
        path: "list",
        name: "newsList",
        component: () => import("@/views/news/content/index"),
        meta: { title: "新闻管理", icon: "Tickets", authorities: ["news_list"] },
        children: []
      },
      {
        path: "edit",
        name: "newsEdit",
        component: () => import("@/views/news/content/edit"),
        meta: { title: "新闻编辑", icon: "Edit", activeMenu: "/content/news" },
        hidden: true
      }
    ]
  },
  {
    path: "/article",
    component: Layout,
    meta: { title: "文章", icon: "el-icon-postcard", authorities: ["article"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/article/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["article"] },
        children: []
      },
      {
        path: "list",
        name: "articleList",
        component: () => import("@/views/article/content/index"),
        meta: { title: "文章管理", icon: "Tickets", authorities: ["article_list"] },
      },
      {
        path: "category",
        name: "contentCategory",
        component: () => import("@/views/article/category/index"),
        meta: { title: "文章分类", icon: "Collection", authorities: ["article_category"] },
      }
    ]
  },
  {
    path: "/ask",
    component: Layout,
    meta: { title: "问答", icon: "el-icon-question", authorities: ["ask"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/ask/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["ask"] },
        children: []
      },
      {
        path: "question",
        name: "questionList",
        component: () => import("@/views/ask/question/index"),
        meta: { title: "问题管理", icon: "Tickets", authorities: ["ask_question"] },
      },
      {
        path: "category",
        name: "questionCategory",
        component: () => import("@/views/ask/category/index"),
        meta: { title: "问题分类", icon: "Collection", authorities: ["ask_question_category"] },
      },
    ]
  },
  {
    path: "/circle",
    component: Layout,
    meta: { title: "社区", icon: "el-icon-discover", authorities: ["circle"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/circle/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["circle"] },
        children: []
      },
      {
        path: "dynamics",
        name: "dynamicsList",
        component: () => import("@/views/circle/dynamic/index"),
        meta: { title: "动态列表", icon: "ChatLineSquare", authorities: ["circle_dynamic"] },
      },
      {
        path: "list",
        name: "circleList",
        component: () => import("@/views/circle/list/index"),
        meta: { title: "社区管理", icon: "ScaleToOriginal", authorities: ["circle_list"] },
      },
      {
        path: "category",
        name: "circleCategory",
        component: () => import("@/views/circle/category/index"),
        meta: { title: "社区分类", icon: "Collection", authorities: ["circle_category"] },
      },
    ]
  },
  {
    path: "/resource",
    component: Layout,
    meta: { title: "知识库", icon: "el-icon-collection", authorities: ["resource"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/resource/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["resource"] },
        children: []
      },
      {
        path: "list",
        name: "resourceList",
        component: () => import("@/views/resource/list/index"),
        meta: { title: "知识管理", icon: "Tickets", authorities: ["resource_list"] },
      },
      {
        path: "category",
        name: "resourceCategory",
        component: () => import("@/views/resource/category/index"),
        meta: { title: "知识分类", icon: "Collection", authorities: ["resource_category"] },
      },
      {
        path: "product",
        name: "resourceProduct",
        component: () => import("@/views/resource/product/index"),
        meta: { title: "知识类别", icon: "CopyDocument", authorities: ["resource_product"] },
      },
      {
        path: "tag",
        name: "resourceTag",
        component: () => import("@/views/resource/tag/index"),
        meta: { title: "知识标签", icon: "PriceTag", authorities: ["resource_tag"] },
      },
    ]
  },
  {
    path: "/point",
    component: Layout,
    meta: { title: "积分", icon: "el-icon-goods", authorities: ["point"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/point/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["point"] },
        children: []
      },
      {
        path: "list",
        name: "pointList",
        component: () => import("@/views/point/list/index"),
        meta: { title: "积分管理", icon: "Coin", authorities: ["point_list"] },
      },
      {
        path: "channel",
        name: "pointChannel",
        component: () => import("@/views/point/channel/index"),
        meta: { title: "积分渠道", icon: "Guide", authorities: ["point_channel"] },
      },
      {
        path: "record",
        name: "pointRecord",
        component: () => import("@/views/point/record/index"),
        meta: { title: "积分记录", icon: "Tickets", authorities: ["point_record"] },
      }
    ]
  },
  {
    path: "/comment",
    component: Layout,
    meta: { title: "评论", icon: "el-icon-chat-dot-square", authorities: ["comment"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/comment/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["comment"] },
        children: []
      },
      {
        path: "list",
        name: "commentList",
        component: () => import("@/views/comment/list/index"),
        meta: { title: "评论管理", icon: "Tickets", authorities: ["comment_list"] }
      },
      {
        path: "sensitive-word",
        name: "commentSensitiveSetting",
        component: () => import("@/views/comment/sensitive-word/index"),
        meta: { title: "敏感词", icon: "Warning", authorities: ["comment_sensitive_setting"] }
      }
    ]
  },
  {
    path: "/search",
    component: Layout,
    meta: { title: "搜索", icon: "el-icon-search", authorities: ["search"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/search/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["search"] },
        children: []
      },
      {
        path: "hot-word",
        name: "searchHotWord",
        component: () => import("@/views/search/hot-word/index"),
        meta: { title: "热词管理", icon: "HotWater", authorities: ["search_hot_word"] }
      }
    ]
  },
  {
    path: "/message",
    component: Layout,
    meta: { title: "消息", icon: "el-icon-chat-dot-round", authorities: ["message"], breadcrumb: false },
    children: [
      {
        path: "",
        component:  () => import("@/views/message/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["message"] },
        children: []
      },
      {
        path: "announcement",
        name: "announcement",
        component:  () => import("@/views/message/announcement/index"),
        meta: { title: "公告管理", icon: "Tickets", authorities: ["message_announcement"] }
      }
    ]
  },
  {
    path: "/member",
    component: Layout,
    meta: { title: "会员", icon: "el-icon-user", authorities: ["member"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/member/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["member"] },
        children: []
      },
      {
        path: "list",
        name: "memberList",
        component: () => import("@/views/member/list/index"),
        meta: { title: "会员管理", icon: "Tickets", authorities: ["member_list"] },
        children: []
      },
      {
        path: "unaudited",
        name: "memberUnauditedList",
        component: () => import("@/views/member/unaudited/index"),
        meta: { title: "未审核会员", icon: "Tickets", authorities: ["member_unaudited_list"] },
        children: []
      },
      {
        path: "level",
        name: "memberLevel",
        component: () => import("@/views/member/level/index"),
        meta: { title: "会员等级", icon: "DataLine", authorities: ["member_level"] },
        children: []
      },
      {
        path: "edit",
        name: "memberEdit",
        component: () => import("@/views/member/list/index"),
        meta: { title: "会员编辑", icon: "Edit", activeMenu: "/member/list" },
        hidden: true,
        children: []
      }
    ]
  },
  {
    path: "/auth",
    component: Layout,
    meta: { title: "权限", icon: "el-icon-unlock", authorities: ["authority"], breadcrumb: false },
    children: [
      {
        path: "",
        component: () => import("@/views/auth/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["authority"] },
        children: []
      },
      {
        path: "organizational",
        component: LayoutEmpty,
        meta: { title: "组织架构", icon: "User", authorities: ["organizational"] },
        redirect: "organizational/user",
        children: [
          {
            path: "user",
            name: "organizationalUser",
            component: () => import("@/views/organizational/user/index"),
            meta: { title: "用户管理", icon: "User", authorities: ["organizational_user"] }
          },
          {
            path: "department",
            name: "organizationalDepartment",
            component: () => import("@/views/organizational/department/index"),
            meta: { title: "组织管理", icon: "School", authorities: ["organizational_department"] }
          }
        ]
      },
      {
        path: "role",
        name: "authorityRole",
        component: () => import("@/views/auth/role/index"),
        meta: { title: "角色管理", icon: "LocationInformation", authorities: ["authority_role"] }
      },
      {
        path: "authority",
        name: "authorityAuthority",
        component: () => import("@/views/auth/authority/index"),
        meta: { title: "权限列表", icon: "Lock", authorities: ["authority_authority"] }
      }
    ]
  },
  {
    path: "/setting",
    component: Layout,
    meta: { title: "系统", icon: "Setting", authorities: ["setting"], breadcrumb: false },
    children: [
      {
        path: "",
        component:  () => import("@/views/setting/index"),
        meta: { title: "概览", icon: "PieChart", authorities: ["setting"] },
        children: []
      },
      {
        path: "carousel",
        name: "settingCarousel",
        component: () => import("@/views/setting/carousel/index"),
        meta: { title: "轮播图管理", icon: "Picture", authorities: ["setting_carousel"] }
      },
      {
        path: "agreement",
        name: "settingAgreement",
        component: () => import("@/views/setting/agreement/index"),
        meta: { title: "协议管理", icon: "Postcard", authorities: ["setting_agreement"] }
      }
    ]
  },
  {
    path: "/account",
    component: Layout,
    meta: { title: "账号中心", icon: "el-icon-setting", authorities: ["setting"], breadcrumb: false },
    hidden: true,
    children: [
      {
        path: "",
        component: () => import("@/views/account/index"),
        meta: { title: "基本信息", icon: "User", authorities: ["setting"] },
        children: []
      },
      {
        path: "carousel",
        name: "sss",
        component: () => import("@/views/account/security/index"),
        meta: { title: "安全设置", icon: "Lock", authorities: ["setting_carousel"] }
      }
    ]
  }
]

let routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
};
let router = createRouter(routerOptions);
export default router;
