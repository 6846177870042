import {checkAuthorities, filterAsyncRoutes, getUserAuthorities, setRoutes} from "@/util/authorityUtils";
import {getToken, refreshToken, removeToken} from "@/util/tokenUtils";
import {getUser} from "@/util/userUtils";
import {error} from "@/util/tipsUtils";
import router, {routes, asyncRoutes} from "./index";
import Index from "../views/home/Index"
import getPageTitle from "../util/getPageTitle";
import {loginOut} from "@/api/login";

// 白名单
const whiteList = ["/login", "/work-we-chat", "/ding-talk"];
const goto = (path, next) => {
  next({ path: path, replace: true })
}
// 选择路径
const switchPath = next => {
  const authorityTargetUrlMap = {
    "member_list": "/member/list",
    "member_level": "/member/level",
    "live_channel": "/live/channel",
    "live_category": "/live/category",
    "learning_list": "/learning/lesson",
    "learning_category": "/learning/category",
    "exam_question_lib": "/exam/question-lib/list",
    "exam_question_lib_category": "/exam/question-lib/category",
    "exam_page_list": "/exam/paper/list",
    "exam_category": "/exam/category",
    "exam_list": "/exam/list",
    "exam_mark": "/exam/mark",
    "exam_mock": "/exam/mock",
    "organizational_user": "/organizational/user",
    "organizational_department": "/organizational/department",
    "authority_role": "/authority/role",
    "authority_authority": "/authority/authority",
    "setting_carousel": "/setting/carousel",
    "setting_agreement": "/setting/agreement",
  }
  let authorityFlag = true
  if (authorityFlag) {
    goto("/index", next)
  }
  for (const authority in authorityTargetUrlMap) {
    if (checkAuthorities([authority])) {
      authorityFlag = false
      goto(authorityTargetUrlMap[authority], next)
    }
  }
  if (authorityFlag) {
    goto("/index", next)
  }
}
const addDynamicRoute = async () => {
  const authorities = await getUserAuthorities();
  if (!authorities || !authorities.length) {
    error("没有权限，请联系管理员");
    loginOut()
    router.push({path: "/login"})
    return false;
  }
  const accessRoutes = filterAsyncRoutes(asyncRoutes, authorities);
  accessRoutes.push({name: "dynamicRoute", path: "/dynamic-route", component: Index, hidden: true})
  const routeList = routes.concat(accessRoutes)
  // 添加路由
  for (const accessedRoute of routeList) {
    await router.addRoute(accessedRoute);
  }
  setRoutes(routeList)
  return true;
}
router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  refreshToken()
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/index" })
    } else {
      const userInfo = getUser()
      if (userInfo) {
        if (to.path === "" || to.path === "/") {
          switchPath(next)
        } else if (to.path === "/login") {
          next();
        } else {
          // 没有动态路由
          if (!router.hasRoute("dynamicRoute")) {
            const flag = await addDynamicRoute();
            if (!flag) {
              return;
            }
            next({ ...to, replace: true });
          } else {
            next();
          }
        }
      } else {
        try {
          // 动态路由
          const flag = await addDynamicRoute();
          if (!flag) { return;}
          if (whiteList.indexOf(from.path) !== -1) {
            switchPath(next);
          } else {
            next({ ...to, replace: true });
          }
        } catch (e) {
          removeToken();
          error(e || "Has Error");
          next("/login");
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
    }
  }
})
router.afterEach(() => {})
