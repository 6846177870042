<template>
  <div class="header">
    <hamburger :is-active="opened" class="hamburger-container" @toggleClick="toggleSideBar"/>
    <breadcrumb class="breadcrumb-container"/>
  </div>
</template>

<script>
import Hamburger from "./Hamburger";
import Breadcrumb from "./Breadcrumb";
import {ref} from "vue";
import store from "../store/index"
export default {
  name: "HeaderIndex",
  components: {
    Hamburger,
    Breadcrumb
  },
  setup() {
    const opened = ref(store.getters.getAsideStatus);
    const toggleSideBar = () => {
      opened.value = !opened.value;
      store.commit("toggleAsideStatus");
    }
    return {
      opened,
      toggleSideBar
    };
  }
};
</script>

<style scoped lang="scss">
.header {
  background: #ffffff;
  overflow: hidden;
  position: relative;
  /*box-shadow: 0 1px 4px rgba(0,21,41,.08);*/
  .hamburger-container {
    line-height: 38px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;
    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }
  .breadcrumb-container {
    float: left;
  }
}
</style>
