<template>
  <el-container>
    <el-header class="layout-header" height="50">
      <layout-header/>
    </el-header>
    <el-main class="layout-main">
      <router-view v-slot="{ Component }">
        <transition>
          <component :is="Component"/>
        </transition>
      </router-view>
    </el-main>
  </el-container>
</template>

<script>
import LayoutHeader from "./LayoutHeader.vue";
import CustomHeader from "./Header.vue";
import CustomFooter from "./Footer.vue";
import CustomAside from "./Aside.vue";
import store from "../store";

export default {
  name: "LayoutFirst",
  components: {
    LayoutHeader,
    CustomHeader,
    CustomFooter,
    CustomAside
  },
  computed: {
    opened() {
      return !store.getters.getAsideStatus
    }
  }
};
</script>

<style scoped lang="scss">
.el-header, .el-footer, .el-main {
  padding: 0!important;
}
.aside {
  position: fixed;
  height: 100%;
  background: #f0f0f0;
}
/*隐藏滚动条*/
.aside::-webkit-scrollbar{
  display:none;
}
.main {
  min-height: 100%;
  position: relative;
  margin-left: 210px;
  transition: width 0.28s;
  width: calc(100% - 210px);
}
.fixed-header {
  top: 0;
  right: 0;
  z-index: 9;
  transition: width 0.28s;
}
.main.fixed-header {
  transition: width 0.28s;
  margin-left: 64px;
  width: calc(100% - 64px);
}
.layout-header {
  font-size: 12px;
  position: fixed;
  z-index: 99;
  width: 100%;
}
.layout-main {
  margin-top: 50px;
}
</style>
