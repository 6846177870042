<template>
  <el-container>
    <el-header class="layout-header" height="50">
      <layout-header/>
    </el-header>
    <el-main class="layout-main">
      <el-container>
        <el-aside class="aside" width="auto">
          <custom-aside v-if="loaded"/>
        </el-aside>
        <el-container class="main" :class="{'fixed-header': opened}">
          <el-header height="40">
            <custom-header/>
          </el-header>
          <el-main>
            <router-view v-slot="{ Component }">
              <transition>
                <component :is="Component"/>
              </transition>
            </router-view>
          </el-main>
        </el-container>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import LayoutHeader from "./LayoutHeader.vue";
import CustomHeader from "./Header.vue";
import CustomAside from "./Aside.vue";
import store from "@/store";
import {nextTick, ref, watch} from "vue";
import {useRoute} from "vue-router";

export default {
  name: "LayoutIndex",
  components: {
    LayoutHeader,
    CustomHeader,
    CustomAside
  },
  computed: {
    opened() {
      return !store.getters.getAsideStatus
    }
  },
  setup() {
    const route = useRoute()
    const loaded = ref(true)
    watch(() => route.path, (n, o) => {
      const ns = n.split("/")
      const os = o.split("/")
      if (ns.length === 1 || os.length === 1 || ns[1] !== os[1]) {
        loaded.value = false
        nextTick(() => {
          loaded.value = true
        })
      }
    })
    return {loaded}
  }
};
</script>

<style scoped lang="scss">
.el-header, .el-footer, .el-main {
  padding: 0!important;
}
.aside {
  position: fixed;
  height: 100%;
  background: #f0f0f0;
}
/*隐藏滚动条*/
.aside::-webkit-scrollbar{
  display:none;
}
.main {
  min-height: 100%;
  position: relative;
  margin-left: 210px;
  transition: width 0.28s;
  width: calc(100% - 210px);
}
.fixed-header {
  top: 0;
  right: 0;
  z-index: 9;
  transition: width 0.28s;
}
.main.fixed-header {
  transition: width 0.28s;
  margin-left: 64px;
  width: calc(100% - 64px);
}
.layout-header {
  font-size: 12px;
  position: fixed;
  z-index: 99;
  width: 100%;
}
.layout-main {
  margin-top: 50px;
}
</style>
