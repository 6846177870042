import {del, get, post, put} from "@/util/requestUtils";

export function getUserInfo(mobile, callback) {
  const params = { mobile: mobile };
  return get("/user-center/auth-api/by-mobile", params, callback);
}

export function getUserList(params, callback) {
  return get("/user-center/list", params, callback);
}

export function updateUser(data, success) {
  return put("/user-center/user", data, success)
}

export function saveUser(data, success) {
  return post("/user-center/user", data, success)
}

export function deleteUser(id, success) {
  return del("/user-center/user", {id: id}, success)
}

// 重置密码
export function resetPwd(data, callback) {
  return put("/user-center/user/reset/pwd", data, callback);
}
