import { createApp } from "vue";
import App from "./App.vue";
// ElementPlus
import ElementPlus from '@/plugins/element'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 页面路由
import router from "@/router";
import "@/router/guard";
// SVG图标
// import svgIcon from "@/assets/svg";
// 缓存
import store from "@/store";
// 登录页面
import Login from "@/views/login/Login";

const app = createApp(App)
export default app.use(store).use(router).use(ElementPlus).use(Login).mount("#app");
// ElementPlusIconsVue全部图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// _ResizeObserver报错
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}