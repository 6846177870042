import {get} from "@/util/requestUtils"

export function getVisitSummary(params, success) {
  return get("/visit-tracking/visit-log/summary", params, success);
}

export function getDayPvList(params, success) {
  return get("/visit-tracking/visit-log/day/pv/list", params, success);
}

export function getDayUvList(params, success) {
  return get("/visit-tracking/visit-log/day/uv/list", params, success);
}

export function getIpCitySummaryList(params, success) {
  return get("/visit-tracking/visit-log/ip-city/summary/list", params, success);
}

