<template>
  <!--  background-image: linear-gradient(90deg, #07c160, #8833ff);-->
  <div class="login-index" :style="'background-image: url('+ loginBg +');'">
    <Login :show="isShow" :show-close="false" @callback="callback" @success="success"/>
    <div class="copyright">
      Copyright © 2023 知否在线学习平台
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import Login from "./Login";
import router from "../../router";

export default {
  name: "LoginIndex",
  components: {
    Login
  },
  setup() {
    // 显示登录框
    const isShow = ref(true);
    // 登录背景
    const loginBg = require("../../assets/login/login-bg.png");
    // 登录回调
    const callback = () => {
      isShow.value = true;
    };
    //登录成功回调
    const success = () => {
      router.push({ path: "/index" });
    };
    return {
      isShow,
      loginBg,
      callback,
      success
    };
  }
};
</script>

<style scoped lang="scss">
.login-index {
  position: fixed;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.hidden {
  display: none;
}
.copyright{
  padding-top: calc(20vh + 530px);
  text-align: center;
  font-size: 14px;
  color: #999999;
  display: none;
}
</style>
